import React from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Assets for Sale</h3>

          <p>
            To view the Notice regarding the closing of sales, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/04/Dkt.-254-Notice-by-Derek-Pierce-regarding-Notice-of-Closing-of-Sales-4850-8841-2769-v1.pdf"
            >
              Notice of Closing of Sales
            </Link>
          </p>
          <p>
            To view the Notice of Rescheduling of Hearing, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/Dkt-211-2-Notice-of-Rescheduling-Hearing-by-DEREK-PIERCE-4844-5228-3995-v1.pdf"
            >
              Notice of Rescheduling of Hearing
            </Link>
             
          </p>
          <p>
            For an overview of the current bids on the portfolio, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Summary-of-Manor-House-Bids-v5.pdf"
            >
              Summary of Manor House Bids.
            </Link>
          </p>
          <p>
            To view the Asset Purchase Agreement for Columbus, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Columbus-APA.pdf"
            >
              Columbus APA.
            </Link>
          </p>
          <p>
            To view the Bidding Procedures Order for Columbus, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-198-ORDER-granting-re-163-Columbus.pdf"
            >
              Columbus Bidding Procedures Order
            </Link>
          </p>
          <p>
            To view the Asset Purchase Agreement for Douglas, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Douglas-APA.pdf"
            >
              Douglas APA.
            </Link>
          </p>
          <p>
            To view the Bidding Procedures Order for Douglas, click here: 
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-205-ORDER-Authorizing-Sale-Douglas-Oxton-Place-4838-5787-5802-v1.pdf"
            >
              Douglas Bidding Procedures Order.
            </Link>
          </p>

          <p>
            To view the Asset Purchase Agreement for Gainesville, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Gainesville-APA.pdf"
            >
              Gainesville APA.
            </Link>
          </p>
          <p>
            To view the Bidding Procedures Order for Gainesville, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-197-ORDER-granting-re-159-Gainesville-v1.pdf"
            >
              Gainesville Bidding Procedures Order
            </Link>
          </p>
          <p>
            To view the Asset Purchase Agreement for Montgomery ("Cedars"),
            click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Montgomery-APA.pdf"
            >
              Montgomery APA.
            </Link>
          </p>
          <p>
            To view the Bidding Procedures Order for Montgomery ("Cedars"),
            click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-203-ORDER-granting-re-183-Application_Petition-4845-2849-8778-v.....pdf"
            >
              Montgomery Bidding Procedures Order
            </Link>
          </p>
          <p>
            To view the Asset Purchase Agreement for Opelika, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Opelika-APA.pdf"
            >
              Opelika APA.
            </Link>
          </p>
          <p>
            To view the Bidding Procedures Order for Opelika, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-200-ORDER-granting-re-171-Opelika.pdf"
            >
              Opelika Bidding Procedures Order
            </Link>
          </p>

          <p>
            To view the Asset Purchase Agreement for Rome, click here:{" "}
            <Link target="_blank" to="../wp-content/uploads/2017/12/Rome-APA.pdf">
              Rome APA.
            </Link>
          </p>
          <p>
            To view the Bidding Procedures Order for Rome, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-204-ORDER-granting-re-191-Application_Petition-4839-0793-8394-v.....pdf"
            >
              Rome Bidding Procedures Order
            </Link>
          </p>
          <p>
            To view the Asset Purchase Agreement for Savannah, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Savannah-APA.pdf"
            >
              Savannah APA.
            </Link>{" "}
          </p>
          <p>
            To view the Bidding Procedures Order for Savannah, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-201-ORDER-granting-re-175-Application_Petition-4819-9159-9962-v.....pdf"
            >
              Savannah Bidding Procedures Order
            </Link>
          </p>
          <p>
            To view the Asset Purchase Agreement for Social Circle, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Social-Circle-APA.pdf"
            >
              Social Circle APA
            </Link>{" "}
          </p>
          <p>
            To view the Bidding Procedures Order for Social Circle, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-196-ORDER-Granting-re-155-Social-Circle.pdf"
            >
              Social Circle Bidding Procedures Order
            </Link>
          </p>
          <p>
            To view the Asset Purchase Agreement for Waterford, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Waterford-APA.pdf"
            >
              Waterford APA
            </Link>{" "}
          </p>
          <p>
            To view the Bidding Procedures Order for Waterford, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-202-ORDER-granting-re-179-Application_Petition-4812-2004-4634-v.....pdf"
            >
              Waterford Bidding Procedures Order
            </Link>
          </p>
          <p>
            The Receiver and his representatives continue to market the Manor
            House Portfolio.  As part of its marketing efforts, the Receiver has
            received and is evaluating one or more offers from interested
            parties for each of the facilities in the Manor House Portfolio. 
            The Receiver is evaluating the information provided by the potential
            bidders with its real estate broker and counsel.  As the U.S.
            District Court for the District of New Jersey (the “Court”) is
            exercising continuing jurisdiction over the Receivership, any
            decision relating to a potential sale of any facility would have to
            be presented to, and approved by, the Court prior to such sale, if
            any, being consummated.
          </p>
        </div>
      </Layout>
    )
  }
}
